import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const App = lazy(() => import("./App"));

const MainApp = () => (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<App />} />
      </Routes>
    </Suspense>
  </Router>
);

ReactDOM.render(<MainApp />, document.getElementById("root"));
